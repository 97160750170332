
	import CreativeHeader from "./CreativeHeader.vue";
	import Vue from "vue";

	export default Vue.extend({
		name: "Creatives",
		props: {},
		components: { CreativeHeader },
		data: () => ({}),
		created() {},
		async mounted() {},
		computed: {},
		methods: {},
	});
